/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'
import { useSwipeable } from 'react-swipeable'
import { ethers } from 'ethers'
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi'
import { fetchBalance } from '@wagmi/core'
import '../App.css'
import PresaleAbi from '../config/PresaleAbi.json'
import '../styles/PresaleContainer.css'
import Input from '../components/Input.tsx'
// import Footer from "../container/Footer.jsx";
import { readContract, writeContract } from '@wagmi/core'
import ClipLoader from 'react-spinners/ClipLoader'
import { waitForTransaction } from '@wagmi/core'
import eth from '../icons/eth.png'
import WTF from '../icons/TokenLogo.png'
import tokenLogo from '../icons/TokenLogo.png'
import wtf from '../icons/wtf.png'
import Web3 from 'web3'
import { useWeb3Modal } from '@web3modal/react'
import styles from '../styles/container/Container.module.scss'
import { toast } from 'react-hot-toast'

import yahoo from '../icons/yahoo.PNG'
import benzinga from '../icons/benzinga.PNG'
import glove from '../icons/glove.PNG'


const slides = [
  {
    img: '/img/slide1.jpg',
    text: 'What’s This For ($WTF) is a revolutionary Token on the Ethereum-compatiblethe Base Chain'
  },
  {
    img: '/img/slide2.jpg',
    text: '$WTF token aims to democratize access to unique asset winnings while offering a robust reward structure'
  },
  {
    img: '/img/slide3.jpg',
    text: 'The $WTF ecosystem uses a unique tax and cooldown strategy to maintain healthy liquidity'
  },
  {
    img: '/img/slide4.jpg',
    text: 'Balanced economic model that supports continuous growth and user engagement through our lottery-based rewards system'
  },
  {
    img: '/img/slide5.jpg',
    text: 'This system enables $WTF holders to buy entries for lotteries that offer tangible prizes, and ensures a fair distribution of rewards'
  },
  {
    img: '/img/slide6.jpg',
    text: 'Portion from the lotteries revenue will be used to fund development and reward our community with stablecoin dividends'
  },
  {
    img: '/img/slide7.jpg',
    text: 'At What’s This For, we are building a robust platform that paves the way for a future where digital and real-world assets are interlinked'
  },
  {
    img: '/img/slide8.jpg',
    text: 'Please read the whitepaper for more details and join our presale now!'
  }
]

function Slideshow() {
  const delay = 3000
  const swipeDelay = 5000 // Delay in ms after swiping before going back to automatic slide show
  const [index, setIndex] = React.useState(0)
  const [isHovering, setIsHovering] = React.useState(false)
  const [swipeDelayActive, setSwipeDelayActive] = React.useState(false)
  const timeoutRef = React.useRef(null)

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }

  useEffect(() => {
    if (!isHovering) {
      resetTimeout()
      const timeoutDuration = swipeDelayActive ? swipeDelay : delay
      timeoutRef.current = setTimeout(
        () =>
          setIndex(prevIndex =>
            prevIndex === slides.length - 1 ? 0 : prevIndex + 1
          ),
        timeoutDuration
      )
    }

    return () => {
      resetTimeout()
    }
  }, [isHovering, index, swipeDelayActive])

  const handleSwipedLeft = () => {
    if (!isHovering) {
      setIndex(prevIndex =>
        prevIndex === slides.length - 1 ? 0 : prevIndex + 1
      )
      setSwipeDelayActive(true)
      resetSwipeDelay()
    }
  }

  const handleSwipedRight = () => {
    if (!isHovering) {
      setIndex(prevIndex =>
        prevIndex === 0 ? slides.length - 1 : prevIndex - 1
      )
      setSwipeDelayActive(true)
      resetSwipeDelay()
    }
  }

  const resetSwipeDelay = () => {
    setTimeout(() => {
      setSwipeDelayActive(false)
    }, swipeDelay)
  }

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleSwipedLeft(),
    onSwipedRight: () => handleSwipedRight(),
    preventDefaultTouchEvent: true
  })

  return (
    <div
      className="slideshow"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div
        className="slideshowSlider"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        <div {...swipeHandlers}>
          {slides.map((slide, idx) => (
            <div
              className="slide"
              key={idx}
              style={{
                backgroundImage: `url(${slide.img})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            >
              <div className="slide-text">{slide.text}</div>
            </div>
          ))}
        </div>
      </div>

      <div className="slideshowDots">
        {slides.map((slide, idx) => (
          <div
            key={idx}
            className={`slideshowDot${index === idx ? ' activeDot' : ''}`}
            onClick={() => setIndex(idx)}
          ></div>
        ))}
      </div>
    </div>
  )
}

const BASE_PROVIDER_URL = 'https://base.meowrpc.com'
let BaseWeb3 = new Web3(new Web3.providers.HttpProvider(BASE_PROVIDER_URL))
const BasePresaleAddress = '0x572AB118586171a81A70219f7bA5795708F702Da'

const App = () => {
  const { address, isConnected } = useAccount()
  const { chain } = useNetwork()
  const [tokenAmount, setAmount] = useState(0)
  const [raisedAmount, setRaisedAmount] = useState(0)
  let [loading, setLoading] = useState(false)
  let [confirming, setConfirming] = useState(false)
  const [PresaleAddress, setPresaleAddress] = useState(BasePresaleAddress)
  const [mainLogo, setMainLogo] = useState(eth)
  const [usdcDecimal] = useState(6)

  const [mainWeb3, setMainWeb3] = useState(BaseWeb3)
  const [selectedTokenName] = useState('Base ETH')
  const [outTokenAmount, setOutTokenAmount] = useState(0)
  let [accountBalance, setAccountBalance] = useState(0)
  const [firstConnect, setFirstConnect] = useState(false)
  const [progress, setProgress] = useState('0%')
  const { open } = useWeb3Modal()
  const [presaleId, setPresaleId] = useState(null)
  const [totalAmount, setTotalAmount] = useState(0)
  const [tokenSoldAmount, setTokenSoldAmount] = useState(0)
  const [presaleStatus, setPresaleStatus] = useState('null')
  const [claimable, setClaimable] = useState(false)
  const [claimableAmount, setClaimableAmount] = useState(0)
  const onConnect = async () => {
    await open()
  }

  const onConnectWallet = async () => {
    await open()
    setFirstConnect(true)
  }

  useEffect(() => {
    const reloadWindow = async () => {
      try {
        window.location.reload()
      } catch (e) {
        console.error(e)
      }
    }
    if (isConnected === true && firstConnect === true) reloadWindow()
  }, [isConnected, firstConnect])

  const { isLoading, pendingChainId, switchNetwork } = useSwitchNetwork()
  useEffect(() => {
    const switchChain = async () => {
      try {
        switchNetwork?.(8453)
      } catch (e) {
        console.error(e)
      }
    }
    if (isConnected === true) {
      if (chain?.id !== 8453) switchChain()
    }
  }, [isConnected, chain?.id, switchNetwork])

  useEffect(() => {
    const setChainData = async () => {
      try {
        setMainWeb3(BaseWeb3)
        setPresaleAddress(BasePresaleAddress)
        setMainLogo(eth)
      } catch (e) {
        console.error(e)
      }
    }
    if (chain?.id === 8453) {
      setChainData()
    }
  }, [chain?.id])

  useEffect(() => {
    if (loading === true) {
      setTimeout(function () {
        setLoading(false)
      }, 3000)
    }
  }, [loading])

  const onTokenDeposit = async () => {
    try {
      setConfirming(true)
      let deposit
      deposit = await writeContract({
        address: PresaleAddress,
        abi: PresaleAbi,
        functionName: 'buyWithEth',
        value: mainWeb3.utils.toWei(String(tokenAmount), 'ether')
      })
      const depositData = await waitForTransaction({
        hash: deposit.hash
      })
      console.log('depositData', depositData)
      setTimeout(function () {
        setConfirming(false)
      }, 3000)
      toast.success(
        `Successfully ${tokenAmount} ${selectedTokenName} deposited`
      )
      const presaleData = await readContract({
        address: PresaleAddress,
        abi: PresaleAbi,
        functionName: 'presale',
        args: [presaleId]
      })
      const Amount = Number(presaleData[5])
      setRaisedAmount(Amount)
      const percent = (Number(raisedAmount) * 100) / totalAmount
      const prog = percent.toFixed(3) + '%'
      setProgress(prog)
    } catch (err) {
      toast.error(
        'There is a problem with your deposit. Please try again later'
      )
      setConfirming(false)
    }
  }

  const onTokenClaim = async () => {
    try {
      setConfirming(true)
      let claim
      claim = await writeContract({
        address: PresaleAddress,
        abi: PresaleAbi,
        functionName: 'claimAmount',
        args: [presaleId]
      })
      const claimData = await waitForTransaction({
        hash: claim.hash
      })
      console.log('claimData', claimData)
      setTimeout(function () {
        setConfirming(false)
      }, 3000)
      toast.success(`Successfully claimed`)
      const claimableData = await readContract({
        address: PresaleAddress,
        abi: PresaleAbi,
        functionName: 'userClaimData',
        args: [address, presaleId]
      })
      let claimableAmounts = ethers.utils.formatEther(claimableData[2])
      setClaimableAmount(claimableAmounts)
    } catch (err) {
      toast.error('There is a problem with your claim. Please try again later')
      setConfirming(false)
    }
  }

  const setMaxAmount = async () => {
    if (accountBalance > 0) accountBalance = accountBalance - 0.0001
    setAmount(accountBalance)
  }

  useEffect(() => {
    const FetchData = async () => {
      try {
        if (address) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          accountBalance = await fetchBalance({ address: address })
          setAccountBalance(accountBalance.formatted)
        }
      } catch (e) {
        console.error(e)
      }
    }

    const FetchGlobalData = async () => {
      try {
        const Id = await readContract({
          address: PresaleAddress,
          abi: PresaleAbi,
          functionName: 'presaleId'
        })
        setPresaleId(Id)
        const presaleData = await readContract({
          address: PresaleAddress,
          abi: PresaleAbi,
          functionName: 'presale',
          args: [Id]
        })
        setRaisedAmount(Number(presaleData[5]))
        setTotalAmount(Number(presaleData[4]))
        setTokenSoldAmount(Number(presaleData[3]))
        setPresaleStatus(presaleData[6])
        setClaimable(presaleData[7])
        if (presaleData[7] === true) {
          const claimableData = await readContract({
            address: PresaleAddress,
            abi: PresaleAbi,
            functionName: 'userClaimData',
            args: [address, Id]
          })
          let claimableAmounts = ethers.utils.formatEther(claimableData[2])
          setClaimableAmount(claimableAmounts)
        }
        FetchData()
      } catch (e) {
        console.error(e)
      }
    }
    if (presaleId == null && PresaleAddress !== undefined) {
      FetchGlobalData()
    } else {
      FetchData()
    }
  }, [address, PresaleAddress, chain?.id])

  useEffect(() => {
    const fetchProgress = async () => {
      try {
        const percent = (Number(raisedAmount) * 100) / totalAmount
        const prog = percent.toFixed(3) + '%'
        setProgress(prog)
      } catch (e) {
        console.error(e)
      }
    }
    if (Number(raisedAmount) > 0) {
      fetchProgress()
    }
  }, [raisedAmount, totalAmount])

  useEffect(() => {
    const FetchData = async () => {
      try {
        let balance
        balance = await readContract({
          address: PresaleAddress,
          abi: PresaleAbi,
          functionName: 'ethToTokens',
          args: [presaleId, mainWeb3.utils.toWei(String(tokenAmount), 'ether')]
        })
        setOutTokenAmount((Number(balance) / 10 ** 18).toFixed(0))
      } catch (e) {
        console.error(e)
      }
    }
    if (tokenAmount > 0) {
      FetchData()
    } else {
      setOutTokenAmount(0)
    }
  }, [PresaleAddress, mainWeb3.utils, presaleId, tokenAmount, usdcDecimal])

  return (
    <div>
      <div className="GlobalContainer">
        {
          <>
            <div className="navBar">
              <div className='socialBox'>
                <img src={tokenLogo} alt="logo" className="tokenHeader" />
                &nbsp; &nbsp; &nbsp;
                <a
                  className="navLink"
                  href="https://whatsthisfor.io"
                  target="_blank"
                  rel="noreferrer"
                >
                  Website
                </a>
                <a
                  className="navLink"
                  href="https://t.me/WhatsThisForToken"
                  target="_blank"
                  rel="noreferrer"
                >
                  Telegram
                </a>
                <a
                  className="navLink"
                  href="https://x.com/WTF_RWAProject"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitter
                </a>
                <a
                  className="navLink"
                  href="https://assets.zyrosite.com/AR0JzpEOvgTJvJ16/wtf-whitepaper-v1-YKbJv4D4n5fGn3Dp.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Whitepaper
                </a>
              </div>

              <div className="ButtonContainer">
                <div className="navConnectButtonBox">
                  {!isConnected ? (
                    <>
                      <button
                        className="navConnectButton"
                        type="submit"
                        onClick={() => {
                          onConnect()
                        }}
                      >
                        Connect<span class="navWallet"> Wallet</span>
                      </button>
                    </>
                  ) : (
                    <section className={styles.ConnectWalletSection}>
                      <div className="ChainGroupButton">
                        {chain?.id === 8453 ? (
                          <button
                            className="navConnectButton"
                            type="submit"
                            onClick={() => onConnect()}
                          >
                            {address.slice(0, 4) + '...' + address.slice(-4)}
                          </button>
                        ) : (
                          <button
                            className="navConnectButton"
                            type="submit"
                            onClick={() => switchNetwork?.(8453)}
                          >
                            {'To Base'}
                            {isLoading && pendingChainId === 8453 && ''}
                          </button>
                        )}
                      </div>
                    </section>
                  )}
                </div>
              </div>
            </div>
            <div className="MainDashboard ResponsiveFlexLayout">
              <section className="LeftColumn">
                <div className="ImageContainer">
                  <img className="ContentToken" src={tokenLogo} />
                  <img className="WTF" src={wtf} />
                </div>
                <h2 className="h2"></h2>
                <h1 className="h1" style={{ textAlign: 'center' }}>
                  Whats This For ($WTF) is a revolutionary Token focused on integrating blockchain technology with RWA (Real World Assets). One of the 1st Lottery Based Raffle Systems on BASE.
                </h1>
                {/* <p className="text">
                  Our mission is to democratize access to unique asset winnings and create an ecosystem that supports both growth and sustainability for our token holders.
                </p> */}

                <p className="whatHeader">Featured In</p>
                <div className="feature">
                  <a href='https://finance.yahoo.com/news/whats-token-wtf-pioneers-way-220000685.html' target="_blank" rel="noreferrer" >
                    <img className="featureLogo" src={yahoo} />
                  </a>
                  <a href='https://www.benzinga.com/pressreleases/24/05/g38631093/whats-this-for-token-wtf-pioneers-the-way-for-real-world-assets-tokenization-into-base-smart-chain' target="_blank" rel="noreferrer"  >
                    <img className="featureLogo" src={benzinga} />
                  </a>
                  <a href='https://www.globenewswire.com/news-release/2024/05/04/2875454/0/en/What-s-This-For-Token-WTF-Pioneers-the-way-for-Real-World-Assets-Tokenization-into-BASE-Smart-Chain.html' target="_blank" rel="noreferrer"  >
                    <img className="featureLogo" src={glove} />
                  </a>
                </div>
                <p className='clickLogo'>Click Logos For 🔗</p>
              </section>
              <section className="ContactBox RightColumn">
                <>
                  <div className="StyledContainer">
                    <section className="ContractContainer">
                      <section className="DepositBoxHeader">
                        <p className="ContractContentTextTitle h1">
                          WTF PRESALE
                        </p>
                      </section>
                      <div className="PresaleContainer">
                        <div className="PresaleProgressContainer">
                          <>
                            <p>NO REFUNDS!</p>
                            <p>ALL TRANSACTIONS ARE FINAL!</p>
                            <p>YOU ARE SOLELY RESPONSIBLE FOR ALL CONFIRMED TRANSACTIONS AND CONTRIBUTIONS?</p>
                          </>
                          <section className="HeaderContent5">
                            <span>
                              Raised : &nbsp; ${' '}
                              {raisedAmount > 0
                                ? Number(
                                  (
                                    Number(raisedAmount) /
                                    10 ** usdcDecimal
                                  ).toFixed(0)
                                ).toLocaleString()
                                : 0}
                            </span>
                            <span>
                              TOKEN AMOUNT SOLD SO FAR :{' '}
                              {Number(
                                (Number(tokenSoldAmount) / 10 ** 18).toFixed(0)
                              ).toLocaleString()}{' '}
                              WTF
                            </span>
                          </section>
                          {Number(claimableAmount) > 0 ? (
                            <section className="HeaderContent5">
                              <span>
                                YOUR HOLD :{' '}
                                {Number(claimableAmount).toLocaleString()} WTF
                              </span>
                            </section>
                          ) : (
                            <></>
                          )}
                        </div>
                        <p class="one_how">1 WTF = $ 0.0003</p>
                        {/* {Number(currentPrice) > 0 ? (1 / Number(currentPrice)).toFixed(3) : 0} */}
                        <section className="tokensButton">
                          <button className="bnbButton tokenButtons">
                            <img className="tokenImage" src={mainLogo} />
                            <span className="tokenButtonText">
                              {'Base ETH'}
                            </span>
                          </button>
                        </section>
                        <section className="BalancePart">
                          {selectedTokenName} Balance :{' '}
                          {Number(accountBalance) > 0.00001
                            ? Number(accountBalance).toFixed(3)
                            : '0'}
                        </section>
                        <>
                          {presaleStatus === true ? ( // true
                            <>
                              <section className="InputBox">
                                <section className="InputSection">
                                  <div className="LpBalance">
                                    <p className="Text1">You Pay : </p>
                                    <p
                                      onClick={setMaxAmount}
                                      className="MaxButton"
                                    >
                                      Max
                                    </p>
                                  </div>
                                  <section className="inputPanel">
                                    <section className="inputPanelHeader">
                                      <Input
                                        placeholder="Enter amount"
                                        label=""
                                        type="number"
                                        changeValue={setAmount}
                                        value={tokenAmount}
                                      />
                                      <img
                                        className="tokenImage"
                                        src={mainLogo}
                                      />
                                    </section>
                                  </section>
                                </section>
                                <section className="InputSection">
                                  <div className="LpBalance">
                                    <p className="Text1">Amount Of Tokens </p>
                                  </div>
                                  <section className="inputPanel">
                                    <section className="inputPanelHeader">
                                      <input
                                        placeholder="0"
                                        label=""
                                        type="number"
                                        value={outTokenAmount}
                                      />
                                      <img className="tokenImage" src={WTF} />
                                    </section>
                                  </section>
                                </section>
                              </section>
                              <>
                                {
                                  <section className="LockBox">
                                    {confirming === false ? (
                                      <>
                                        {isConnected && chain?.id === 8453 ? (
                                          <>
                                            <button
                                              disabled={
                                                Number(tokenAmount) <= 0
                                              }
                                              onClick={onTokenDeposit}
                                              className="PresaleButton"
                                            >
                                              {Number(tokenAmount) <= 0
                                                ? 'Please enter token amount'
                                                : 'Deposit Token Now!'}
                                            </button>
                                            {claimable === true &&
                                              claimableAmount > 0 ? (
                                              <button
                                                disabled={false}
                                                onClick={onTokenClaim}
                                                className="PresaleButton"
                                              >
                                                Claim (
                                                {Number(
                                                  claimableAmount
                                                ).toFixed(3)}{' '}
                                                $WTF)
                                              </button>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <p className="Text1">Confirming...</p>
                                        <ClipLoader
                                          color={'#36d7b7'}
                                          loading={confirming}
                                          size={30}
                                          aria-label="Loading Spinner"
                                          data-testid="loader"
                                        />
                                      </>
                                    )}
                                    {!isConnected ? (
                                      <>
                                        <button
                                          className="PresaleButton"
                                          type="submit"
                                          onClick={() => {
                                            onConnectWallet()
                                          }}
                                        >
                                          Connect Wallet
                                        </button>
                                      </>
                                    ) : (
                                      <>
                                        {chain?.id === 8453 ? (
                                          <></>
                                        ) : (
                                          <button
                                            className="PresaleButton"
                                            type="submit"
                                            onClick={() =>
                                              switchNetwork?.(8453)
                                            }
                                          >
                                            {'Switch To Base'}
                                            {isLoading &&
                                              pendingChainId === 8453 &&
                                              ' (switching)'}
                                          </button>
                                        )}
                                      </>
                                    )}
                                  </section>
                                }
                              </>
                            </>
                          ) : presaleStatus === false ? (
                            <>
                              <p class="ContractContentTextTitle">
                                Presale is not live yet
                              </p>
                            </>
                          ) : (
                            <>
                              {!isConnected ? (
                                <></>
                              ) : (
                                <>
                                  <p className="Text1">Data Loading...</p>
                                  <ClipLoader
                                    color={'#36d7b7'}
                                    loading={confirming}
                                    size={30}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                  />
                                </>
                              )}
                            </>
                          )}
                        </>
                        <div className="ButtonContainer">
                          {!isConnected ? (
                            <></>
                          ) : (
                            <>
                              {chain?.id === 8453 ? (
                                <></>
                              ) : (
                                <div className="ChainGroupButton">
                                  <button
                                    className="ConnectButton"
                                    type="submit"
                                    onClick={() => switchNetwork?.(8453)}
                                  >
                                    {'To Base'}
                                    {isLoading && pendingChainId === 8453 && ''}
                                  </button>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </section>
                  </div>
                </>
                <Slideshow />
              </section>
            </div>
          </>
        }
      </div>
      <section className="footer">
        <p className="footerText">Copyright 2024 | All rights reserved.</p>
      </section>
    </div>
  )
}

export default App
